<template>
  <main id="about">
    <breadcrumb title="Kurumsal" subTitle=""/>
    <div class="bd-about__section pt-120 pb-90">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="bd-about__img w-img mr-70 mb-30 wow fadeInRight">
              <img :src="about.img" alt="kurumsal"/>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="bd-about__text mb-30 wow fadeInLeft">
              <div class="bd-section__title-wrapper">
                <span class="bd-section__subtitle bd-section__subtitle-lg mb-10 text-lowercase">
                 1958'den beri
                </span>
                <h3 class="bd-section__title bd-section__title-lg mb-25">{{ about.title }}</h3>
                <div class="lp" v-html="about.desc"></div>
              </div>
            </div>
          </div>
          <div class="col-12 lpRev  wow fadeInUp" v-html="about.desc">
          </div>
        </div>
      </div>
    </div>

    <div class="bd-portfolio__section pb-120">
      <div class="container">
        <div class="row align-items-center justify-content-center mb-70">
          <div class="col-lg-6 text-center text-lg-end mb-3 wow fadeInLeft">
            <img :src="about.mimg" alt="mission" class="img-fluid">
          </div>
          <div class="col-lg-6">
            <div class="bd-portfolio__text wow fadeInUp">
              <div class="bd-section__title-wrapper">
                <h3 class="bd-section__title bd-section__title-lg mb-1">
                  {{ about.mtitle }}
                </h3>
                <p class="mb-25">
                  {{about.mdesc}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6">
            <div class="bd-portfolio__text  wow fadeInUp">
              <div class="bd-section__title-wrapper">
                <h3 class="bd-section__title bd-section__title-lg mb-1 text-md-end">{{ about.vtitle }}</h3>
                <p class="mb-25 text-md-end">
                  {{ about.vdesc}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-center text-lg-start mb-3 wow fadeInRight">
            <img :src="about.vimg" alt="vision" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
    <app-footer/>
  </main>
</template>
<script>
import breadcrumb from "@/components/Common/Breadcrumb";
import appFooter from "@/components/Common/Footer";
import axiosCustom from "@/axiosCustom";

export default {
  name: 'App',
  components: {breadcrumb, appFooter},
  data() {
    return {
      about: null
    }
  },
  created() {
    document.title = 'Kurumsal'
    axiosCustom
        .get('about')
        .then(response => {
          this.about = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
