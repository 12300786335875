<template>
  <div class="breadcrumb__section include-bg" :style="{ backgroundImage: 'url(' + require('@/assets/img/parallax/breadcrumb.png') + ')' }">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="breadcrumb__content d-md-flex justify-content-between align-items-center">
            <h3 class="breadcrumb__title">{{title}}</h3>
            <div class="breadcrumb__list">
              <span><router-link to="/anasayfa">Anasayfa</router-link></span>
              <span class="dvdr">/</span>
              <template v-if="subTitle">
                <span >{{subTitle}}</span>
                <span class="dvdr">/</span>
              </template>
              <span>{{ title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props:['title','subTitle']
}
</script>

<style scoped>

</style>